@tailwind base;
@tailwind components;
@tailwind utilities;
@import './fonts.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Commissioner';
  font-style: normal;
}

body,
html {
  scrollbar-width: none;
  overflow-y: scroll;
}

img {
  -webkit-user-drag: none;
}

// .mainPage {
//   background-image: url('../../shared/images/background.png');
// }
// .page404,
// .errorPage {
//   background-image: url('../../shared/images/background404.png');
// }

@media only screen and (max-height: 740px) {
  #root {
    grid-template-rows: 90px 1fr 100px;
  }
  #sadIcon {
    top: 7%;
  }
}
