@import url('https://fonts.googleapis.com/css2?family=Commissioner:wght@100..900&display=swap');

.commissioner-thin {
  font-family: 'Commissioner', sans-serif;
  font-weight: 100;
  font-style: normal;
  font-optical-sizing: auto;
}

.commissioner-light {
  font-family: 'Commissioner', sans-serif;
  font-weight: 300;
  font-style: normal;
  font-optical-sizing: auto;
}

.commissioner-regular {
  font-family: 'Commissioner', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-optical-sizing: auto;
}

.commissioner-medium {
  font-family: 'Commissioner', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-optical-sizing: auto;
}

.commissioner-bold {
  font-family: 'Commissioner', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-optical-sizing: auto;
}

.commissioner-extrabold {
  font-family: 'Commissioner', sans-serif;
  font-weight: 800;
  font-style: normal;
  font-optical-sizing: auto;
}

.commissioner-black {
  font-family: 'Commissioner', sans-serif;
  font-weight: 900;
  font-style: normal;
  font-optical-sizing: auto;
}
